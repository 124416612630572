













































import { base64ToBlob, blobToFile, compare } from '@/common/utils'
import {
  callAppFunc_openSystemAlbum,
  callAppFunc_previewImage,
  callH5Func_choiceImageSuccess,
  callH5Func_delPreviewImage
} from '@/common/utils/bridge'
import { uploadImage } from '@/pages/user/api'
import { CommonModule } from '@/store/modules/common'
import { Toast } from 'vant'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'IOSUpload'
})
export default class extends Vue {
  fileList: Array<any> = []

  @Prop()
  list!: Array<UploadFileList>

  @Watch('list')
  onList(list: Array<UploadFileList>) {
    this.fileList = list
  }

  @Emit('change')
  @Watch('fileList')
  onFileList(list: Array<UploadFileList>) {
    return list
  }

  mounted() {
    callH5Func_delPreviewImage((url: string) => {
      for (const index in this.fileList) {
        if (this.fileList[index].url == url) {
          this.fileList.splice(Number(index), 1)
          break
        }
      }
    })

    callH5Func_choiceImageSuccess(async (base64: string) => {
      console.log('base64:', base64)

      const blob = base64ToBlob(base64)
      const file = blobToFile(blob, String(new Date().getTime()))
      console.log('file:', file)
      const id = new Date().getTime()
      const obj = {
        id,
        status: 'uploading',
        url: '',
        rate: 0
      }
      this.fileList.push(obj)

      const data = new FormData()
      data.append('image', file)
      try {
        await uploadImage(data).then(res => {
          obj.status = 'done'
          obj.url = res.data
          obj.rate = 100
        })
      } catch (error) {
        obj.status = 'failed'
      }
      this.fileList = this.fileList.map(item => {
        if (item.id == id) {
          item = {
            ...item,
            ...obj
          }
        }
        return item
      })
    })
  }

  clickPreview(url: string) {
    callAppFunc_previewImage(url)
  }

  deleteItem(index: number) {
    this.fileList.splice(index, 1)
  }

  openSystemAlbum() {
    callAppFunc_openSystemAlbum()
  }
}
